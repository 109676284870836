<template>
    <div ref="mapContainer" class="map-container"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;

export default {
  data: () => ({
    location: {
      lng: 14.3001435907641,
      lat: 47.6077978292689,
      bearing: 0,
      pitch: 0,
      zoom: 8,
    },
    map: null,
    marker: null,
    geocoder: null,
  }),
  props: ['modelValue'],
  mounted() {
    const {
      lng, lat, zoom, bearing, pitch,
    } = this.modelValue;
    const map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom,
      bearing,
      pitch,
    });
    this.marker = new mapboxgl.Marker()
      .setLngLat([lng, lat])
      .addTo(map);
    this.geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
      placeholder: 'Search for a location',
      marker: false,
    });
    this.map = map;
    this.map.on('load', () => {
      this.map.resize();
    });
    this.map.addControl(this.geocoder);
    this.geocoder.on('result', (event) => {
      const { result } = event;
      if (result && result.geometry && result.geometry.coordinates) {
        const [_lng, _lat] = result.geometry.coordinates;
        this.updateLocation(_lng, _lat);
      }
    });
  },
  watch: {
    modelValue: {
      handler(val) {
        const {
          lng, lat, zoom, bearing, pitch,
        } = val;
        this.marker.remove();
        this.marker = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(this.map);
        this.map.flyTo({
          center: [lng, lat],
          zoom,
          bearing,
          pitch,
        });
      },
      deep: true,
    },
  },
  methods: {
    updateLocation(lng, lat) {
      this.$emit('update:modelValue', { ...this.modelValue, lng, lat });
      this.marker.setLngLat([lng, lat]);
      this.map.flyTo({ center: [lng, lat], zoom: this.modelValue.zoom });
    },
    handleSearchInput(event) {
      const query = event.target.value;
      if (query) {
        this.geocoder.query(query);
      }
    },
  },
  unmounted() {
    this.map.remove();
    this.map = null;
  },
};
</script>

<style>
.map-container {
  flex: 1;
}
</style>
