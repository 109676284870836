<template>
  <div>
    <div class="header popup-offer" id="header-popup-offer">
      <div class="make-offer-header">
        <h1>Request Offer</h1>
        <div class="make-offer-action">
          <button class="btn btn-primary"
            :disabled="isLoading || confirmDelete"
            @click="closeModal()">Cancel</button>
          <button type="submit"
            class="btn btn-primary"
            @click="filterFormData()"
            :disabled="isLoading || confirmDelete">
            Publish
          </button>
          <button v-if="!isNewProject"
            class="btn btn-secondary"
            :disabled="confirmDelete"
            @click="showConfirmationDelete">
            Delete
          </button>
        </div>
      </div>
    </div>
    <div class="error-wrapper">
      <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    </div>
    <div class="confirmation" v-if="confirmDelete">
      <h4>Are you sure you want to delete this Project?</h4>
      <div class="confirmation-actions">
        <a href="" @click.prevent="deleteProject(true)">Yes</a>
        <a href="" @click.prevent="deleteProject(false)">No</a>
      </div>
    </div>
    <!-- project: {{ project }} -->
    <section class="make-offer-content">
      <ValidationObserver slim>
        <form class="mb-4">
          <div class="mb-2">
            Marketplace: <strong>{{ marketplaceName }}</strong>
          </div>
          <div class="form-col-1 mb-2">
            <label class="required">Project name</label>
            <input class="form-control project-name" type="text" v-model="form.name"
              placeholder="The project name will be automatically generated">
          </div>
          <div class="form-col form-col-2 mb-2">
            <div class="form-col-1">
              <label class="required">Company Name</label>
              <input class="form-control" type="text" v-model="form.company_name">
            </div>
            <div class="form-col-1">
              <label class="required">Project Country</label>
              <Multiselect
              v-if="countries"
              v-model="form.company_country"
              :options="countries"
              label="name"
              track-by="id"
              placeholder="Select country"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"/>
            </div>
          </div>
          <div class="form-col form-col-1 mb-2">
            <label class="required">Project location</label>
            <input class="form-control" type="text" v-model="form.company_address"
            @input="debouncedGetCoordinates">
          </div>
          <div v-if="isNewProject && filteredTextAndDropboxFields" class="form-col form-col-2">
            <div v-for="(data, index) in filteredTextAndDropboxFields"
            :key="index" class="form-col-1 mb-2">
              <div  v-if="data[1].type === 'dropbox'">
                <label :class="{required: data[1].required === true}">{{ data[1].name }}
                  <div v-if="data[1].notice !== ''">
                    <i class="icon-about" :id="`${data[1].name}-create-project-popup`"></i>
                    <BTooltip
                      custom-class="tooltip-info"
                      :target="`${data[1].name}-create-project-popup`"
                      :title="data[1].notice"
                      triggers="hover"
                      placement="top"
                    />
                  </div>
                </label>
                <!-- {{ form.data[data[0]] }} -->
                  <Multiselect
                    v-if="data[1].values"
                    v-model="form.data[data[0]]"
                    :multiple="data[1].multiple"
                    :options="data[1].values.map((value, idx) => ({ id: idx, name: value }))"
                    label="name"
                    track-by="id"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"/>
              </div>
              <div v-else>
                <label :class="{required: data[1].required === true}">{{ data[1].name }}
                  <div v-if="data[1].notice !== ''">
                    <i class="icon-about" :id="`${data[1].name}-create-project-popup`"></i>
                    <BTooltip
                      custom-class="tooltip-info"
                      :target="`${data[1].name}-create-project-popup`"
                      :title="data[1].notice"
                      triggers="hover"
                      placement="top"
                    />
                  </div>
                </label>
                <input class="form-control" type="text"
                :placeholder="data[1].placeholder"
                :required="data[1].required === true" :id="`${data[0]}-input`"
                :class="{required: data[1].required === true}" v-model="form.data[data[0]]">
              </div>
            </div>
          </div>
          <div v-else-if="!isNewProject && filteredTextAndDropboxFields"
            class="form-col form-col-2">
            <div v-for="(data, index) in filteredTextAndDropboxFields"
            :key="index" class="form-col-1 mb-2">
              <div v-if="data.type === 'dropbox'">
                <label :class="{required: data.required === true}">{{ data.name }}
                  <div v-if="data.notice !== ''">
                    <i class="icon-about" :id="`${data.name}-create-project-popup`"></i>
                    <BTooltip
                      custom-class="tooltip-info"
                      :target="`${data.name}-create-project-popup`"
                      :title="data.notice"
                      triggers="hover"
                      placement="top"
                    />
                  </div>
                </label>
                  <Multiselect
                    v-if="data.values"
                    v-model="form.data[data.id]"
                    :multiple="data.multiple"
                    :options="data.values.map((value, idx) => ({ id: idx, name: value }))"
                    label="name"
                    track-by="id"
                    :close-on-select="!data.multiple"
                    :clear-on-select="false"
                    :preserve-search="true"/>
              </div>
              <div v-else>
                <label :class="{required: data.required === true}">{{ data.name }}
                  <div v-if="data.notice !== ''">
                    <i class="icon-about" :id="`${data.name}-create-project-popup`"></i>
                    <BTooltip
                      custom-class="tooltip-info"
                      :target="`${data.name}-create-project-popup`"
                      :title="data.notice"
                      triggers="hover"
                      placement="top"
                    />
                  </div>
                </label>
                <input class="form-control" type="text"
                :required="data.required === true" :id="`${data}-input`"
                :placeholder="data.placeholder"
                :class="{required: data.required === true}" v-model="form.data[data.id]">
              </div>
            </div>
          </div>
          <div class="form-col-1 location mb-2">
            <label>Location</label>
            <div class="map-wrapper">
              <Map :modelValue="location"/>
            </div>
          </div>
          <div v-if="isNewProject && filteredTextareaFields" class="mb-4">
            <div v-for="(data, index) in filteredTextareaFields" :key="index"
              class="form-col-1 mb-4">
              <div>
                <label :class="{required: data[1].required === true}">{{ data[1].name }}
                  <div v-if="data[1].notice !== ''">
                    <i class="icon-about" :id="`${data[1].name}-create-project-popup`"></i>
                    <BTooltip
                      custom-class="tooltip-info"
                      :target="`${data[1].name}-create-project-popup`"
                      :title="data[1].notice"
                      triggers="hover"
                      placement="top"
                    />
                  </div>
                </label>
                <textarea class="form-textarea"
                :required="data[1].required === true" :id="`${data[0]}-textarea`"
                :class="{required: data[1].required === true}" v-model="form.data[data[0]]"
                :placeholder="data.placeholder"
                cols="30" rows="5" maxlength="500"></textarea>
              </div>
            </div>
          </div>
          <div v-else-if="!isNewProject && filteredTextareaFields" class="mb-4">
            <div v-for="(data, index) in filteredTextareaFields" :key="index"
              class="form-col-1 mb-4">
                <div>
                  <label :class="{required: data.required === true}">{{ data.name }}
                  <div v-if="data.notice !== ''">
                    <i class="icon-about" :id="`${data.name}-create-project-popup`"></i>
                    <BTooltip
                      custom-class="tooltip-info"
                      :target="`${data.name}-create-project-popup`"
                      :title="data.notice"
                      triggers="hover"
                      placement="top"
                    />
                  </div>
                </label>
                <textarea class="form-textarea"
                :required="data.required === true" :id="`${data}-textarea`"
                :placeholder="data.placeholder"
                :class="{required: data.required === true}" v-model="form.data[data.id]"
                cols="30" rows="5" maxlength="500"></textarea>
                </div>
            </div>
          </div>
          <div class="addmedia pt-4">
            <div class="addmedia__downloaded">
              <h5 class="addmedia__downloaded-title" style="font-weight: 600;">
                Presentation and documentation
              </h5>
              <div v-if="docs.length">
                <h6>Documents ({{docs.length}}):</h6>
                <ul >
                  <li class="addmedia__downloaded-item" v-for="item in docs" :key="item.url">
                    <span class="icon-pdf"></span>
                    <span class="addmedia__downloaded-name">
                      {{ item.title }}
                    </span>
                    <span class="icon-delete pointer" @click="onRemoveFile(item)"></span>
                  </li>
                </ul>
              </div>
              <div v-if="images.length">
                <h6>Images ({{images.length}}):</h6>
                <ul class="media-list">
                  <li class="media-item" v-for="item in images" :key="item.url">
                    <span class="icon-delete pointer" @click="onRemoveFile(item)"></span>
                    <img :src="item.url" alt="">
                    <span>{{ item.title }}</span>
                  </li>
                </ul>
              </div>
              <div v-if="videos.length">
                <h6>Video ({{videos.length}}):</h6>
                <ul class="media-list">
                  <li class="media-item" v-for="item in videos" :key="item.thumbnail">
                    <span class="icon-delete pointer" @click="onRemoveFile(item)"></span>
                    <div v-html="item.html"></div>
                    <span>{{ item.name }}</span>
                  </li>
                </ul>
              </div>
              <div class="addmedia__title">
            Add Media:
            </div>
            <ul class="addmedia__list">
              <li>
                <i class="icon-file"></i>
              </li>
              <li>
                <i class="icon-youtube"></i>
              </li>
              <li>
                <i class="icon-image"></i>
              </li>
            </ul>
              <div class="addmedia__wrapper">
                <h5 class="addmedia__wrapper-title">Add a Video</h5>
                <span class="addmedia__wrapper-notice">Here you can insert a URL from
                  a video platform (for example YouTube). The video will then be embedded.
                </span>
                <input
                  v-model="selectedVideo"
                  type="text"
                  name="url"
                  pattern="^https?://.+$"
                  placeholder="Insert URL"
                  class="form-control">
                <button class="btn btn-primary"
                  :disabled="isLoading || !selectedVideo"
                  @click="uploadFile('video')">
                  Add video
                </button>
              </div>
              <div class="addmedia__wrapper">
                <h5 class="addmedia__wrapper-title">Add a File</h5>
                <span class="addmedia__wrapper-notice">Here you can upload various files
                  to be displayed in a media carousel. You are able to upload PDFs,
                  images, or gifs.
                </span>
                <div v-if="this.selectedFile">
                  {{ this.selectedFile.name }}
                  <span class="icon-delete pointer" @click="removeSelectedFile"></span>
                </div>
                <div class="file-drop">
                  <div class="file-drop__body">
                    <input type="file"
                      @change="onChangeFile"
                      ref="inputFiles"
                      accept="application/pdf, video/*, image/*">
                    <img src="@/assets/images/upload.png" alt="" class="file-drop__icon">
                    <div class="file-drop__info">
                      Formats: PDF, image<br>
                      Max: 10Mb
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-primary"
                  :disabled="isLoading || !selectedFile"
                  @click="uploadFile('document')">
                  Add file
                </button>
              </div>
            </div>
          </div>
          <div class="form-line fr-2 mb-2">
            <label>Allow to make Offers?</label>
            <div id="radio-container">
              <input type="radio" id="yes" name="switcher"
                v-model="form.offers_allow" value="1">
              <label for="yes">Yes</label><br>
              <input type="radio" id="no" name="switcher"
                v-model="form.offers_allow" value="3">
              <label for="no">No</label>
            </div>
          </div>
          <div class="form-line fr-2 mb-2">
            <label>Offer Deadline</label>
            <DatePicker
              class="form-control"
              style="margin-bottom: 0.5rem;"
              v-model="form.deadline_at"
              :disabled-date="disabledDate"
              format="DD.MM.YYYY"
              placeholder="DD.MM.YYYY"
            />
            <!-- <BFormDatepicker :min="new Date()"
              v-model="form.deadline_at" locale="gb" placeholder="DD.MM.YYYY"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"/> -->
          </div>
          <!-- <div class="form-line fr-2">
            <label>Status</label>
            <Multiselect
              style="max-width: 200px;"
              v-if="fields"
              v-model="form.status"
              :options="[{ id: 1, name: 'Open' }, { id: 3, name: 'Close' }]"
              label="name"
              track-by="id"
              deselectLabel=""
              selectLabel=""
              placeholder="Select status"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"/>
          </div> -->
        </form>
      </ValidationObserver>
    </section>
    <div class="error-wrapper">
      <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    </div>
    <div class="make-offer-bottom">
      <div class="make-offer-action">
        <button class="btn btn-primary"
          :disabled="isLoading || confirmDelete"
          @click="closeModal()">Cancel</button>
        <button type="submit"
          class="btn btn-primary"
          @click="filterFormData()"
          :disabled="isLoading || confirmDelete">
          Publish
        </button>
        <button v-if="!isNewProject"
          class="btn btn-secondary"
          :disabled="confirmDelete"
          @click="showConfirmationDelete">
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import exception from '@/mixins/exception';
import alert from '@/mixins/alert';
import { BTooltip } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import MatchmakingService from '@/services/MatchmakingService';
import moment from 'moment';
import Alert from '@/components/Alert.vue';
import axios from 'axios';
import Map from '@/components/Map.vue';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'FormRequest',
  mixins: [exception, alert],
  data() {
    return {
      form: {
        id: 0,
        marketplace_id: this.marketplaceId,
        name: '',
        company_name: '',
        company_country: '',
        company_address: '',
        data: {},
        deadline_at: '',
        offers_allow: 1,
        status: { id: 1, name: 'Open' },
        documents: [],
        videos: [],
      },
      filteredForm: {
        data: {},
      },
      tmpForm: {},
      date_create: new Date().getTime(),
      wasEditName: false,
      isLoading: false,
      selectedVideo: '',
      selectedFile: null,
      docs: [],
      videos: [],
      images: [],
      confirmDelete: false,
      token: process.env.VUE_APP_MAPBOX_TOKEN,
      location: {
        lng: 14.3001435907641,
        lat: 47.6077978292689,
        bearing: 0,
        pitch: 0,
        zoom: 8,
      },
    };
  },
  components: {
    Multiselect, BTooltip, DatePicker, Alert, Map,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    marketplaceId: {
      type: Number,
      required: true,
    },
    marketplaceName: {
      type: String,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    formId: {
      type: String,
      required: true,
    },
    isNewProject: {
      type: Boolean,
      required: true,
    },
    project: {
      type: Object,
      required: false,
    },
  },
  computed: {
    defaultProjectTitle() {
      if (this.wasEditName) {
        return this.form.name;
      }
      let firstFieldValue = '';
      let secondFieldValue = '';

      Object.keys(this.form.data).some((key) => {
        const fieldValue = this.form.data[key];
        if (typeof fieldValue === 'object' && fieldValue.name) {
          if (!firstFieldValue) {
            firstFieldValue = fieldValue.name;
          } else if (!secondFieldValue) {
            secondFieldValue = fieldValue.name;
            return true;
          }
        } else if (Array.isArray(fieldValue)) {
          fieldValue.forEach((item) => {
            if (typeof item === 'object' && item.name) {
              if (!firstFieldValue) {
                firstFieldValue = item.name;
              } else if (!secondFieldValue) {
                secondFieldValue = item.name;
                return true;
              }
            }
            return false;
          });
        } else if (fieldValue) {
          if (!firstFieldValue) {
            firstFieldValue = fieldValue;
          } else if (!secondFieldValue) {
            secondFieldValue = fieldValue;
            return true;
          }
        }
        return false;
      });
      let result = '';
      if (this.form.company_country) {
        result += `${this.form.company_country.name}-`;
      }
      if (firstFieldValue) {
        result += `${firstFieldValue}-`;
      }
      if (secondFieldValue) {
        result += `${secondFieldValue}-`;
      }
      result += moment(this.date_create).format('DD-MM-YYYY');
      return result;
    },
    filteredTextareaFields() {
      if (this.isNewProject) {
        return this.fields.filter((field) => field[1].type === 'textarea');
      }
      return this.fields.filter((field) => field.type === 'textarea');
    },
    filteredTextAndDropboxFields() {
      if (this.isNewProject) {
        return this.fields.filter((field) => field[1] && field[1].type !== 'textarea');
      }
      return this.fields.filter((field) => field.type !== 'textarea');
    },
  },
  watch: {
    'form.name': function formName() {
      this.wasEditName = true;
    },
    formId() {
      this.clearForm();
      this.form.id = this.formId;
    },
  },
  created() {
    this.clearForm();
    this.form.id = this.formId;
    if (!this.isNewProject) {
      this.form.name = this.project.name;
      this.form.company_name = this.project.company_name;
      this.form.company_country = this.project.company_country;
      this.form.company_address = this.project.company_address;
      this.project.data.forEach((item) => {
        if (item.type === 'text') {
          this.$set(this.form.data, item.id, item.initValue);
        } else if (item.type === 'dropbox') {
          if (item.multiple) {
            // eslint-disable-next-line no-lonely-if
            if (item.initValue !== null) {
              this.$set(this.form.data, item.id, item.initValue
                .map((value) => ({ id: value, name: item.values[value] })));
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (item.initValue !== null) {
              this.$set(this.form.data, item.id,
                { id: item.initValue, name: item.values[item.initValue] });
            }
          }
        } else if (item.type === 'textarea') {
          this.$set(this.form.data, item.id, item.initValue);
        }
      });
      const formattedDate = moment(this.project.deadline_at, 'DD/MM/YY').format('YYYY-MM-DD');
      this.form.deadline_at = formattedDate;
      this.form.offers_allow = this.project.offers_allow;
      this.form.status = this.project.status === 1 ? { id: 1, name: 'Open' } : { id: 3, name: 'Close' };
      this.form.videos = this.project.videos;
      this.form.documents = this.project.documents;
      this.date_create = this.project.date_create;
      this.wasEditName = true;
      this.getCoordinates();
      this.project.documents.forEach((item) => {
        if (item.url.includes('pdf')) {
          this.docs.push(item);
        } else {
          this.images.push(item);
        }
      });
      // this.project.videos = project.videos;
    }
  },
  onUnmounted() {
    this.clearForm();
  },
  methods: {
    disabledDate(date) {
      return date < new Date();
    },
    removeSelectedFile() {
      this.selectedFile = null;
      this.$refs.inputFiles.value = '';
    },
    async debouncedGetCoordinates() {
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(async () => {
        await this.getCoordinates();
      }, 1000);
    },
    async getCoordinates() {
      try {
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(this.form.company_address)}.json?access_token=${this.token}`,
        );
        const [lng, lat] = response.data.features[0].center;
        this.location.lng = lng;
        this.location.lat = lat;
      } catch (error) {
        console.error('Wrong address');
      }
    },
    deleteProject(predicate) {
      if (predicate) {
        if (!this.isNewProject) {
          this.$emit('onDeleteProject', this.project.id);
        }
        this.closeModal();
      }
      this.confirmDelete = false;
    },
    showConfirmationDelete() {
      this.confirmDelete = !this.confirmDelete;
      const modalRequestOffer = document.getElementById('header-popup-offer');
      if (modalRequestOffer) {
        modalRequestOffer.scrollIntoView({ behavior: 'smooth' });
      }
    },
    onChangeFile(event) {
      // check max file size 10MB
      if (event.target.files[0].size > 10000000) {
        this.setAlert({ textArg: 'File size should be less than 10MB', variantArg: 'danger' });
        return;
      }
      [this.selectedFile] = event.target.files;
    },
    async readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result.split(',')[1]);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    },
    async uploadFile(_type) {
      try {
        this.isLoading = true;
        this.removeAlert();
        const requestData = {
          type: 'document',
          title: '',
          file: null,
        };
        if (_type === 'document') {
          const data = await this.readFileAsBase64(this.selectedFile);
          requestData.file = data;
          requestData.title = this.selectedFile.name;
        } else {
          requestData.file = this.selectedVideo;
          requestData.title = '';
          requestData.type = _type;
        }
        const response = await MatchmakingService.uploadFile(this.formId, requestData);
        if (_type === 'video') {
          this.videos = response.data;
        } else {
          response.data.forEach((item) => {
            if (item.url.includes('pdf')) {
              if (this.docs.filter((doc) => doc.url === item.url).length === 0) {
                this.docs.push(item);
              }
            } else {
              /* eslint-disable no-lonely-if */
              if (this.images.filter((image) => image.url === item.url).length === 0) {
                this.images.push(item);
              }
            }
          });
        }
        this.selectedFile = null;
        this.selectedVideo = '';
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async onRemoveFile(item) {
      try {
        this.isLoading = true;
        console.log(item, 'item');
        const requestData = {
          type: !item.type ? 'video' : 'document',
          file: item.url,
        };
        const response = await MatchmakingService.removeFile(this.form.id, requestData);
        if (!item.type) {
          this.videos = response.data;
          this.selectedVideo = '';
          this.form.video = '';
        } else {
          if (item.url.includes('pdf')) {
            this.docs = response.data.filter((doc) => doc.url.includes('pdf'));
          } else {
            this.images = response.data.filter((image) => !image.url.includes('pdf'));
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    clearForm() {
      this.form = {
        id: 0,
        marketplace_id: this.marketplaceId,
        name: '',
        company_name: '',
        company_country: '',
        company_address: '',
        data: {},
        deadline_at: '',
        offers_allow: 1,
        status: { id: 1, name: 'Open' },
      };
      this.filteredForm = {
        data: {},
      };
      this.date_create = new Date().getTime();
      this.wasEditName = false;
      this.selectedVideo = '';
      this.selectedFile = null;
      this.textInputFile = '';
      this.isBadFile = false;
      this.docs = [];
      this.videos = [];
      this.images = [];
      this.confirmDelete = false;
    },
    closeModal() {
      this.clearForm();
      this.$emit('close');
    },
    filterFormData() {
      this.removeAlert();
      if (!this.defaultProjectTitle) {
        this.setAlert({ textArg: 'Please enter project name', variantArg: 'danger' });
        return;
      }
      if (this.form.status === null) {
        this.setAlert({ textArg: 'Please select status', variantArg: 'danger' });
        return;
      }
      if (!this.form.company_name) {
        this.setAlert({ textArg: 'Please enter company name', variantArg: 'danger' });
        return;
      }
      if (!this.form.company_country) {
        this.setAlert({ textArg: 'Please select company country', variantArg: 'danger' });
        return;
      }
      if (!this.form.company_address) {
        this.setAlert({ textArg: 'Please enter company address', variantArg: 'danger' });
        return;
      }
      if (this.isNewProject) {
        for (let i = 0; i < this.fields.length; i += 1) {
          const field = this.fields[i];
          if (field[1].required === true && !this.form.data[field[0]]) {
            this.setAlert({ textArg: `Please enter ${field[1].name}`, variantArg: 'danger' });
            return;
          }
        }
        Object.keys(this.form.data).forEach((key) => {
          if (typeof (this.form.data[key]) === 'object') {
            const isMultiple = this.fields.find((field) => field[0] === key)[1].multiple;
            if (isMultiple) {
              this.filteredForm.data[key] = this.form.data[key].map((item) => item.id);
            } else {
              this.filteredForm.data[key] = this.form.data[key].id;
            }
          } else {
            this.filteredForm.data[key] = this.form.data[key];
          }
        });
      } else {
        for (let i = 0; i < this.fields.length; i += 1) {
          const field = this.fields[i];
          if (field.required === true && !this.form.data[field.id]) {
            this.setAlert({ textArg: `Please enter ${field.name}`, variantArg: 'danger' });
            return;
          }
        }
        Object.keys(this.form.data).forEach((key) => {
          if (typeof (this.form.data[key]) === 'object') {
            const isMultiple = this.fields.find((field) => field.id === key).multiple;
            if (isMultiple) {
              this.filteredForm.data[key] = this.form.data[key].map((item) => item.id);
            } else {
              this.filteredForm.data[key] = this.form.data[key].id;
            }
          } else {
            this.filteredForm.data[key] = this.form.data[key];
          }
        });
      }
      // if (!this.form.deadline_at) {
      //  this.setAlert({ textArg: 'Please select deadline', variantArg: 'danger' });
      //  return;
      // }
      this.filteredForm.company_country = this.form.company_country.iso;
      if (this.form.deadline_at) {
        const deadlineDate = new Date(this.form.deadline_at);
        const formattedDeadline = `${deadlineDate.getDate()}.${deadlineDate.getMonth() + 1}.${deadlineDate.getFullYear()}`;
        this.filteredForm.deadline_at = formattedDeadline;
      }
      this.filteredForm.offers_allow = this.form.offers_allow;
      this.filteredForm.status = this.form.status.id;
      this.filteredForm.company_name = this.form.company_name;
      this.filteredForm.company_address = this.form.company_address;
      this.filteredForm.name = this.defaultProjectTitle;
      this.filteredForm.marketplace_id = this.marketplaceId;
      this.filteredForm.id = this.formId;
      this.sendFormData();
    },
    async sendFormData() {
      try {
        this.isLoading = true;
        this.removeAlert();
        if (this.isNewProject) {
          await MatchmakingService.createMarketplaceProject(this.filteredForm);
          this.$emit('onSaveProject', this.marketplaceId, true);
          this.setAlert({ textArg: 'Project created successfully', variantArg: 'success' });
        } else {
          await MatchmakingService.updateMarketplaceProject(this.project.id, this.filteredForm);
          this.$emit('onUpdateProject');
          this.setAlert({ textArg: 'Project updated successfully', variantArg: 'success' });
        }
        this.clearForm();
      } catch (e) {
        console.log(e);
        if (e.response.data.message) {
          this.setAlert({ textArg: e.response.data.message, variantArg: 'danger' });
        } else {
          this.setAlert({ textArg: e.response.data[0].message, variantArg: 'danger' });
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-wrapper {
  margin: 10px 0;
}
.pointer {
  cursor: pointer;
}
// .fr-2 {
//   grid-template-columns: repeat(2, 1fr);
// }

.make-offer-content {
  //max-height: 60vh;
  padding: 0 5px;
  //overflow: auto;
}

.make-offer-bottom {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}

.required::before {
  content: '*';
  color: red;
  margin-right: 4px;
}
</style>
